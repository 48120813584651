import React, { FC, useState, useEffect } from 'react';
import { BeetleIcon } from 'assets/tsx/beetle-icon';
import { graphql } from 'gatsby';
import { PageLayout, ContactLocation } from 'components';
import { submitContactUs, resetBookAppointment } from 'Redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { SeoType } from '../pages/index';

interface ContactPageType {
  data: {
    contactPage: {
      title: string;
      description: string;
      quotation: {
        content: string;
      };
      seo: SeoType;
      contactLocation: {
        address: string;
        callTitle?: string;
        appointmentTitle?: string;
        contactNumber?: string;
        latitudeValue: string;
        longitudeValue: string;
        title: string;
      };
      contactForm: {
        buttonText: string;
        queryTitle: string;
        title: string;
        formFailureMessage: string;
        formSuccessMessage: string;
        heardAboutUsTitle: string;
        heardAboutUs: {
          value: string;
          id: number;
        }[];
        contactQuery: {
          title: string;
          id: number;
        }[];
      };
    };
    site: {
      siteMetadata: {
        contactUsURL: string;
        googleMapAPI: string;
      };
    };
  };
}

const ContactUs: FC<ContactPageType> = ({ data }) => {
  const dispatch = useDispatch();
  const [select, selectedValue] = useState(
    data.contactPage.contactForm.contactQuery[0].title
  );

  const {
    isSubmitFailure,
    isSubmitSuccess,
    isNameInvalid,
    isNameRequired,
    isEmailRequired,
    isEmailInvalid,
    isPhoneNumberRequired,
    isPhoneNumberInvalid,
    isMessageRequired,
    isMessageInvalid,
    isheardAboutUsRequired,
  } = useSelector((state: any) => {
    return state.ContactUsReducer;
  });
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [contactUsMessage, changecontactUsMessage] = useState('');
  const [phonenumber, changephonenumber] = useState('');
  const [heardAboutus, changeheardAboutus] = useState('');

  const {
    title,
    description,
    seo,
    quotation: { content: quotation },
  } = data.contactPage;
  const {
    title: formTitle,
    queryTitle,
    contactQuery,
    buttonText,
    heardAboutUs,
    formFailureMessage,
    formSuccessMessage,
    heardAboutUsTitle,
  } = data.contactPage.contactForm;
  const contactUsUrl = data.site.siteMetadata.contactUsURL;
  const handleSubmit = (e: any) => {
    if (isSubmitSuccess === true) {
      dispatch(resetBookAppointment());
    }
    e.preventDefault();
    dispatch(
      submitContactUs({
        name,
        email,
        phonenumber,
        contactUsMessage,
        select,
        contactUsUrl,
        heardAboutus,
      })
    );
  };

  useEffect(() => {
    if (isSubmitSuccess === true) {
      changeName('');
      changeEmail('');
      changephonenumber('');
      changecontactUsMessage('');
      changeheardAboutus('');
    }
  }, [isSubmitSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetBookAppointment());
    };
  }, []);

  return (
    <PageLayout
      title={title}
      quotation={quotation}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      showBookAppointmentForm={false}
      className="container"
    >
      <div className="contact-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="page-description">
          <p>{description}</p>
        </div>
        <ContactLocation contact={data.contactPage} site={data.site} />
        <div className="contact-page-form">
          <h3 className="contact-page-form-title">{formTitle}</h3>
          {isSubmitSuccess && (
            <div className="submit-message">{formSuccessMessage}</div>
          )}
          {isSubmitFailure && (
            <div className="submit-message">{formFailureMessage}</div>
          )}
          <div className="contact-page-form-message">
            <div className="contact-page-form-textarea">
              <textarea
                name="contact_message"
                className="contact-message"
                rows={9}
                cols={20}
                value={contactUsMessage}
                onChange={e => {
                  changecontactUsMessage(e.target.value);
                }}
              />
              {isMessageRequired && (
                <div className="submit-message">* Required</div>
              )}
              {isMessageInvalid && (
                <div className="submit-message">Field Cannot be Empty</div>
              )}
            </div>
            <div className="contact-page-form-checkbox">
              <div className="contact-page-form-checkbox-wrapper">
                <form noValidate id="contact-us" onSubmit={handleSubmit}>
                  <div className="form_fields contact-page-heardaboutus-form">
                    <div className="form_fields_text">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={name}
                        onChange={e => {
                          changeName(e.target.value);
                        }}
                      />
                      {isNameRequired && (
                        <div className="submit-message">*Required</div>
                      )}
                      {isNameInvalid && (
                        <div className="submit-message">
                          Minimum 2 characters or more
                        </div>
                      )}
                    </div>
                    <div className="form_fields_text">
                      <input
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={e => {
                          changeEmail(e.target.value);
                        }}
                      />
                      {isEmailRequired && (
                        <div className="submit-message">*Required</div>
                      )}
                      {isEmailInvalid && (
                        <div className="submit-message">Invalid Email ID</div>
                      )}
                    </div>
                    <div className="form_fields_text">
                      <input
                        type="number"
                        name="Phone Number"
                        placeholder="Phone Number"
                        value={phonenumber}
                        onChange={e => {
                          changephonenumber(e.target.value);
                        }}
                      />
                      {isPhoneNumberRequired && (
                        <div className="submit-message">*Required</div>
                      )}
                      {isPhoneNumberInvalid && (
                        <div className="submit-message">
                          Invalid Mobile Number. Use 10 digits only
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="contact-page-heardaboutus-form">
                    <h5 className="contact-page-form-checkbox-title">
                      {heardAboutUsTitle}
                    </h5>
                    <select
                      className="form_select"
                      onChange={e => {
                        changeheardAboutus(e.target.value);
                      }}
                    >
                      <option value="select" disabled selected>
                        Select
                      </option>
                      {heardAboutUs.map((item: any) => {
                        return (
                          <option value={item.value} key={item.id}>
                            {item.value}
                          </option>
                        );
                      })}
                    </select>
                    {isheardAboutUsRequired && (
                      <div className="submit-message">
                        *Please select one in the list{' '}
                      </div>
                    )}
                  </div>
                  <div className="contact-page-form-radio">
                    <h5 className="contact-page-form-checkbox-title">
                      {queryTitle}
                    </h5>
                    {contactQuery.map((content: any) => {
                      return (
                        <div
                          className="contact-page-form-checkbox-radioButton"
                          key={content.id}
                        >
                          <input
                            type="radio"
                            name={content.title}
                            value={content.title}
                            checked={select === content.title}
                            onChange={() => selectedValue(content.title)}
                          />
                          <span
                            role="button"
                            tabIndex={0}
                            className="contact-page-label"
                            onClick={() => selectedValue(content.title)}
                            onKeyDown={() => selectedValue(content.title)}
                          >
                            {content.title}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="contact-page-form-submit">
                    <span className="submit">
                      <button type="submit" className="action">
                        {buttonText}
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div />
        </div>
        <div className="contact-page-beetle-icon">
          <BeetleIcon />
        </div>
      </div>
    </PageLayout>
  );
};

export default ContactUs;
export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        contactUsURL
        googleMapAPI
      }
    }
    contactPage: strapiContactUsPage {
      title
      description
      quotation {
        content
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      contactLocation {
        address
        callTitle
        contactNumber
        appointmentTitle
        latitudeValue
        longitudeValue
        title
      }
      contactForm {
        buttonText
        queryTitle
        title
        formFailureMessage
        formSuccessMessage
        heardAboutUsTitle
        heardAboutUs {
          value
          id
        }
        contactQuery {
          title
          id
        }
      }
    }
  }
`;
