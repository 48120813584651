import React from 'react';

const BeetleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="422"
      height="291"
      viewBox="0 0 422 291"
    >
      <defs>
        <path
          id="wy0y3l5nba"
          d="M1.324 3.168L126.698 3.168 126.698 58.818 1.324 58.818z"
        />
        <path
          id="hcjch21p8c"
          d="M0 3.168L125.625 3.168 125.625 58.818 0 58.818z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#CB874D"
          d="M167.691 216.233c-10.976 0-20.1-3.666-27.162-10.919-23.036-23.65-16.753-76.88-16.476-79.135l.425-3.461 1.674 3.083c3.06 5.642 19.514 8.526 25.596 9.16 16.596 3.527 19.733 8.574 20.033 9.139l-1.969 1.02c-.031-.047-2.944-4.72-18.427-8.022-.734-.07-18.628-1.998-25.367-8.035-.967 11.136-3.343 54.77 16.136 74.766 7.258 7.444 16.843 10.866 28.567 10.137l.138 2.167c-1.07.065-2.13.1-3.168.1"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M173.73 216.233c-1.038 0-2.098-.035-3.172-.1l-1.047-.065v-58.765h2.237v56.715c11.214.465 20.476-2.967 27.515-10.19 19.474-19.992 17.103-63.624 16.14-74.765-6.743 6.037-24.646 7.97-25.492 8.057-15.357 3.28-18.275 7.953-18.302 8l-1.973-1.02c.304-.565 3.44-5.612 19.913-9.121 6.206-.652 22.66-3.536 25.716-9.178l1.674-3.083.425 3.461c.277 2.255 6.564 55.49-16.476 79.14-7.062 7.248-16.186 10.914-27.158 10.914M148.464 240.874c-.569 0-1.137-.134-1.665-.408-6.01-3.106-12.968-6.84-15.304-8.643l-.076.126c-9.92-5.568-27.65-45.522-28.401-47.22-1.526-2.402-2.014-4.795-1.378-6.971.904-3.071 3.745-4.487 3.861-4.548 2.645-2.237 17.528-7.957 19.215-8.6l.819 2.02c-4.488 1.711-16.615 6.558-18.799 8.374-.241.156-2.33 1.22-2.948 3.37-.452 1.564-.04 3.366 1.212 5.36.255.547 18.172 40.944 27.475 46.3l.072.038-.005.01c.971.581 7.943 4.66 15.308 8.464.69.36 1.244-.004 1.401-.117.152-.122.644-.565.448-1.299l-1.768-6.667c-.264-.994-1.038-1.81-2.063-2.184-3.414-1.238-9.97-4.509-15.527-12.526l-21.25-30.451c-.225-.439-1.361-2.954-.032-4.7.863-1.133 2.434-1.559 4.716-1.268l14.95.378-.062 2.172-15.067-.387c-1.454-.178-2.407-.035-2.734.391-.434.573-.063 1.85.175 2.345l21.16 30.308c5.21 7.514 11.295 10.558 14.454 11.7 1.714.622 3.003 1.999 3.446 3.68l1.772 6.666c.353 1.338-.125 2.693-1.24 3.549-.64.486-1.4.738-2.165.738"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M192.883 240.874c-.77 0-1.53-.252-2.17-.738-1.114-.856-1.589-2.21-1.24-3.544l1.772-6.672c.443-1.68 1.732-3.057 3.446-3.678 3.164-1.143 9.25-4.187 14.453-11.701l21.255-30.46c.153-.364.515-1.628.081-2.193-.327-.43-1.284-.573-2.613-.4l-15.116.396-.058-2.172 14.995-.387c2.143-.29 3.732.14 4.595 1.277 1.33 1.742.193 4.261.059 4.543l-21.34 30.612c-5.554 8.013-12.114 11.284-15.528 12.522-1.025.373-1.794 1.19-2.063 2.184l-1.767 6.667c-.193.734.295 1.177.452 1.299.152.113.711.478 1.4.117 7.254-3.744 14.337-7.883 15.308-8.465l-.009-.009.103-.056c9.317-5.433 27.189-45.735 27.368-46.143 1.333-2.133 1.74-3.935 1.289-5.499-.618-2.15-2.703-3.214-2.726-3.227-2.407-1.959-14.534-6.806-19.022-8.517l.82-2.02c1.69.643 16.574 6.363 19.438 8.748l-.005-.005c.063 0 2.77 1.425 3.643 4.405.635 2.176.152 4.569-1.45 7.11-.676 1.55-18.244 41.156-28.196 47.003v.004-.004c-.045.026-.094.052-.134.078l-.076-.126c-2.336 1.802-9.29 5.537-15.3 8.643-.532.274-1.1.408-1.664.408M125.954 129.088c-2.524-2.072-3.463-5.664-2.792-10.676.018-.243 2.815-30.494 47.468-33.118l.139 2.168c-42.725 2.506-45.36 30.88-45.383 31.167-.568 4.235.13 7.258 2.013 8.8l-1.445 1.659z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M216.708 127.633l-1.852-1.217c1.185-1.698 1.548-4.3 1.087-7.726-.094-1.225-2.752-28.727-45.383-31.233l.134-2.167c44.654 2.623 47.45 32.879 47.477 33.183.524 3.909.036 7.005-1.463 9.16M170.147 145.212c-23.569-26.794-42.233-30.56-44.287-30.903l-9.567 1.55c-2.743.24-4.868-.477-6.403-2.158-4.488-4.925-1.915-16.496-1.804-16.986 2.6-9.803 7.102-18.125 11.868-26.933 1.042-1.924 2.094-3.866 3.132-5.846l5.942-11.28c.6-1.15 1.862-1.763 3.155-1.55 1.311.204 2.287 1.16 2.488 2.436l.474 2.971c.157.999-.219 1.99-1.011 2.65-2.448 2.05-2.99 3.457-3.007 4.273-.018.6.237 1.13.765 1.62 1.088.995 1.28 2.58.457 3.77-5.665 8.174-11.25 17.03-13.756 26.394-.568 2.137-1.4 8.613.13 10.529.237.3.492.425.855.425 1.454 0 7.11-.92 9.164-1.277l.394 2.137c-.779.135-7.639 1.312-9.558 1.312-1.056 0-1.965-.439-2.627-1.268-2.663-3.345-.546-12.318-.52-12.405 2.587-9.672 8.288-18.724 14.06-27.058.215-.309.162-.713-.138-.982-.985-.912-1.495-2.037-1.463-3.249.044-1.828 1.32-3.805 3.785-5.868.202-.165.296-.425.26-.673l-.475-2.97c-.071-.487-.465-.6-.63-.626-.18-.026-.582-.04-.81.395l-5.938 11.284c-1.048 1.985-2.1 3.935-3.146 5.868-4.703 8.69-9.147 16.9-11.675 26.429-.68 3-1.794 11.64 1.307 15.04 1.047 1.151 2.492 1.62 4.43 1.442l9.236-1.533c.326-.056.666-.06 1.002-.004 3.481.578 22.034 4.825 45.611 31.636l-1.7 1.408z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M171.362 145.212l-1.7-1.408c23.577-26.81 42.125-31.058 45.606-31.636.345-.052.685-.052 1.011.004l9.308 1.546c1.848.187 3.302-.304 4.354-1.455 3.096-3.4 1.982-12.04 1.293-15.08-2.52-9.49-6.958-17.698-11.666-26.39-1.042-1.932-2.098-3.882-3.145-5.867l-5.934-11.284c-.233-.434-.64-.421-.81-.395-.165.026-.56.139-.635.625l-.475 2.971c-.035.248.063.508.26.673 2.47 2.063 3.74 4.04 3.786 5.868.03 1.212-.48 2.337-1.464 3.245-.295.273-.353.677-.138.986 5.772 8.334 11.473 17.377 14.064 27.058.022.087 2.139 9.06-.52 12.405-.662.83-1.57 1.268-2.63 1.268-1.916 0-8.78-1.177-9.554-1.312l.394-2.137c2.054.356 7.705 1.277 9.16 1.277.366 0 .617-.126.859-.425 1.526-1.916.698-8.392.13-10.529-2.51-9.368-8.095-18.22-13.76-26.394-.82-1.19-.627-2.775.456-3.774.532-.486.783-1.016.77-1.616-.023-.816-.56-2.223-3.012-4.274-.792-.66-1.168-1.65-1.011-2.649l.474-2.97c.202-1.278 1.177-2.233 2.488-2.437 1.298-.217 2.555.4 3.16 1.55l5.937 11.28c1.038 1.98 2.09 3.922 3.137 5.846 4.761 8.808 9.263 17.13 11.854 26.898.125.525 2.694 12.096-1.79 17.021-1.54 1.685-3.67 2.41-6.323 2.167l-9.397-1.555c-2.304.34-20.969 4.105-44.537 30.899"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M196.108 92.259l-1.767-1.338c2.734-3.396 5.146-6.736 4.698-10.285-.161-1.299-.738-2.684-1.763-4.239-1.423-2.154-3.101-3.744-4.99-4.717-3.525-1.815-7.857-1.646-12.73-1.277l-8.748.674-9.021-.674c-4.869-.373-9.2-.538-12.731 1.277-1.888.973-3.566 2.563-4.99 4.717-1.02 1.555-1.597 2.94-1.762 4.24-.448 3.548 1.968 6.888 4.698 10.284l-1.763 1.338c-2.913-3.618-5.71-7.518-5.16-11.883.206-1.63.896-3.314 2.104-5.147 1.629-2.476 3.593-4.318 5.826-5.468 4.385-2.255 9.692-1.85 13.957-1.52l8.752.673 8.659-.674c4.264-.334 9.567-.738 13.957 1.52 2.237 1.151 4.193 2.993 5.826 5.469 1.208 1.833 1.897 3.518 2.099 5.147.55 4.365-2.242 8.265-5.15 11.883"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <g transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06) translate(214.788 110.576)">
          <mask id="a6cnfkmzvb" fill="#fff">
            <use xlinkHref="#wy0y3l5nba" />
          </mask>
          <path
            fill="#CB874D"
            d="M44.093 58.818c-15.92 0-31.864-1.124-41.964-2.949-.268-.047-.537-.113-.805-.173l.268-2.172c.488.113.716.17.949.213 21.071 3.813 67.82 4.565 85.633-1.698 11.004-3.87 19.595-10.802 26.267-21.191 3.57-5.555 6.368-11.77 8.31-18.468.094-.339 1.087-4.804 1.575-7.04H12.108V3.168h114.137l.34.43c.658.847-1.66 9.286-1.677 9.368-2.005 6.898-4.887 13.3-8.565 19.028-6.95 10.82-15.912 18.047-27.408 22.086-9.536 3.353-27.17 4.738-44.842 4.738"
            mask="url(#a6cnfkmzvb)"
          />
        </g>
        <path
          fill="#CB874D"
          d="M298.145 141.553c-10.032 0-17.774-1.455-18.48-1.594-50.212-11.71-63.144-12.56-63.27-12.57l.13-2.166c.515.03 13.116.838 63.627 12.617.331.06 38.34 7.21 56.123-10.646l1.606 1.512c-10.44 10.48-26.952 12.847-39.736 12.847M297.524 162.527c-13.63 0-25.944-3.7-26.642-3.913-54.395-14.515-54.431-14.698-54.597-15.57l1.101-.2.465-.982c.273.109 4.1 1.442 53.666 14.672.264.078 22.915 6.875 40.273 2.097l.613 2.09c-4.806 1.32-9.93 1.806-14.879 1.806"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <g transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06) translate(0 110.576)">
          <mask id="7fz7x1duad" fill="#fff">
            <use xlinkHref="#hcjch21p8c" />
          </mask>
          <path
            fill="#CB874D"
            d="M82.61 58.818c-17.676 0-35.306-1.385-44.842-4.738-11.496-4.04-20.463-11.267-27.408-22.086C6.677 26.261 3.8 19.86 1.795 12.966 1.773 12.884-.54 4.445.117 3.598l.34-.43h114.438V5.34H2.377c.488 2.232 1.477 6.697 1.575 7.04 1.938 6.693 4.734 12.904 8.305 18.468 6.677 10.389 15.264 17.32 26.267 21.19 17.814 6.264 64.562 5.508 85.638 1.699.224-.044.452-.096.676-.152l.277-.061.51 2.11c-.523.122-.783.188-1.051.235-10.1 1.825-26.048 2.95-41.965 2.95"
            mask="url(#7fz7x1duad)"
          />
        </g>
        <path
          fill="#CB874D"
          d="M43.342 141.553c-12.79 0-29.297-2.367-39.736-12.847l1.606-1.512C23 145.05 61 137.904 61.384 137.831c50.462-11.77 63.067-12.578 63.582-12.608l.13 2.167c-.126.008-13.058.86-63.229 12.56-.725.144-8.484 1.603-18.525 1.603M43.966 162.527c-4.949 0-10.072-.486-14.878-1.806l.613-2.09c17.38 4.782 40.009-2.02 40.237-2.084 49.602-13.243 53.424-14.576 53.701-14.685l.408.973 1.154.208c-.166.873-.201 1.056-54.632 15.584-.667.204-12.973 3.9-26.603 3.9M170.75 2.47c-15.671 0-28.424 12.374-28.424 27.589 0 15.21 12.753 27.584 28.424 27.584 15.675 0 28.423-12.374 28.423-27.584 0-15.215-12.748-27.589-28.423-27.589m0 57.345c-16.902 0-30.661-13.351-30.661-29.756 0-16.409 13.76-29.76 30.66-29.76 16.906 0 30.662 13.351 30.662 29.76 0 16.405-13.756 29.756-30.661 29.756"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M141.97 31.028L140.453 29.434 149.022 21.764 149.022 9.064 161.332 9.064 170.783 0 179.464 9.064 192.852 9.064 192.852 21.799 200.504 29.478 198.898 30.985 190.615 22.681 190.615 11.236 178.488 11.236 170.716 3.118 162.249 11.236 151.259 11.236 151.259 22.715z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M170.785 60.106L161.334 51.042 149.024 51.042 149.024 38.338 140.455 30.672 141.972 29.078 151.262 37.391 151.262 48.87 162.252 48.87 170.713 56.988 178.49 48.87 190.617 48.87 190.617 37.426 198.895 29.121 200.506 30.628 192.854 38.303 192.854 51.042 179.462 51.042z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M144.557 42.95l-1.276-1.786 6.041-4.074c1.204-1.793 11.353-17.251 11.353-26.941h2.237c0 10.906-11.357 27.61-11.84 28.314l-.291.295-6.224 4.191zM196.462 42.95l-6.515-4.487c-.483-.704-11.84-17.408-11.84-28.314h2.237c0 9.69 10.149 25.148 11.352 26.941l6.041 4.074-1.275 1.785z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M160.227 56.842l-2.085-.79 2.833-6.837c.241-.251 6.215-6.263 30.5-11.83l.519 2.11c-21.502 4.93-28.245 10.203-29.252 11.08l-2.515 6.267z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M180.311 56.842l-2.515-6.271c-.984-.86-7.718-6.137-29.247-11.076l.52-2.11c24.28 5.567 30.253 11.579 30.5 11.83l.223.344 2.605 6.493-2.086.79zM149.635 23.158l-.515-2.11c21.497-4.93 28.24-10.203 29.243-11.08l2.519-6.268 2.085.791-2.832 6.836c-.242.252-6.211 6.263-30.5 11.831"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M192.044 23.158c-24.289-5.568-30.258-11.579-30.5-11.83l-.224-.344-2.608-6.493 2.085-.79 2.52 6.271c.984.86 7.714 6.137 29.242 11.076l-.515 2.11zM160.695 50.17c-.036-.178-3.822-17.872-11.286-27.08l-6.018-3.14 1.056-1.911 6.569 3.522c7.893 9.573 11.715 27.424 11.871 28.175l-2.192.435z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
        <path
          fill="#CB874D"
          d="M181.115 50.17l-2.192-.434c.156-.751 3.978-18.602 11.871-28.175l.345-.278 6.224-3.244 1.056 1.911-6.018 3.14c-7.464 9.217-11.25 26.902-11.286 27.08M165.742 150.897l5.003 4.856 4.998-4.856-4.998-4.855-5.003 4.855zm5.003 7.927l-8.167-7.927 8.167-7.926 8.162 7.926-8.162 7.927z"
          transform="translate(-922 -1414) translate(923 1414) translate(40.273 26.06)"
        />
      </g>
    </svg>
  );
};
export { BeetleIcon };
